<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <nav class="fixed w-full top-0 z-20 bg-gray-800">
    <div class="mx-auto px-2 ">
      <div class="relative flex items-center justify-between h-16">
        <div class=" inset-y-0 left-0 flex items-center ">
          <!-- Mobile menu button-->
          <button
            class="sm:hidden inline-flex items-center justify-center p-2 rounded-md text-gray-200 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <!-- Icon when menu is closed. -->
            <!--
            Heroicon name: menu

            Menu open: "hidden", Menu closed: "block"
          -->
            <svg
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <!-- Icon when menu is open. -->
            <!--
            Heroicon name: x

            Menu open: "block", Menu closed: "hidden"
          -->
            <svg
              class="hidden h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div
          class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"
        >
          <div class="flex-shrink-0 flex items-center">
            <router-link :to="{ name: 'dashboard' }">
              <LogoAmoba class="w-32" theme="dark"
            /></router-link>
          </div>
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <!--  <button
            class="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          >
            <span class="sr-only">View notifications</span>
            Heroicon name: bell
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          </button> -->

          <!-- Profile dropdown -->
          <Dropdown>
            <template #activator>
              <button
                class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                id="user-menu"
                aria-haspopup="true"
              >
                <span class="sr-only">Open user menu</span>

                <div class="h-8 w-8 rounded-full bg-gray-500">
                  <img
                    v-if="photo"
                    ref="profileDropdown"
                    @click.prevent="toggleDropdown"
                    class="h-full w-full rounded-full"
                    :src="photo"
                    alt=""
                  />
                </div>
              </button>
            </template>
            <template #content>
              <DropdownMenuItem>
                <router-link
                  :to="{ name: 'profile', params: { email: email } }"
                  target="_blank"
                >
                  Ver perfil
                </router-link>
              </DropdownMenuItem>
              <DropdownMenuItem @click="logout">
                Salir
              </DropdownMenuItem>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import LogoAmoba from '@/components/LogoAmoba.vue'

import Dropdown from '@/components/common/dropdown/Dropdown.vue'
import DropdownMenuItem from '@/components/common/dropdown/DropdownMenuItem.vue'
import { useStore } from 'vuex'
import { LOGOUT } from '@/store/modules/auth/actions.type'
import { AUTH_STORE_NAME } from '@/store//modules/auth/index.js'
import { computed } from 'vue'

export default {
  components: {
    Dropdown,
    DropdownMenuItem,
    LogoAmoba
  },
  setup() {
    const store = useStore()

    const photo = computed(
      () => store.getters['auth/authUserData']?.aboutMe.photo
    )
    const email = computed(() => store.getters['auth/authUserData']?.email)

    return {
      logout: () => store.dispatch(`${AUTH_STORE_NAME}/${LOGOUT}`),
      photo,
      email
    }
  }
}
</script>
