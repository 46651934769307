<template>
  <div class="relative" v-if="authUserData">
    <Navbar />
    <div class="relative">
      <AsideContent
        v-if="isOpenAsideContent"
        :isOpen="isOpenAsideContent"
        :class="{
          'ml-40': !isCollapsedAsideMenu,
          'ml-14': isCollapsedAsideMenu
        }"
      />
      <AsideMenu
        v-model:isCollapsed="isCollapsedAsideMenu"
        :class="{
          'w-40': !isCollapsedAsideMenu,
          'w-14': isCollapsedAsideMenu
        }"
      />
    </div>
    <TransitionComponent>
      <Summary
        class="transition-all duration-500 ease-in-out"
        :class="{
          'ml-40': !isCollapsedAsideMenu,
          'ml-14': isCollapsedAsideMenu
        }"
        :data="authUserData"
      />
    </TransitionComponent>
  </div>
</template>

<script>
import { ref } from 'vue'
import Navbar from '@/components/navbar/Navbar.vue'
import Summary from '@/components/summary/Summary.vue'
import AsideMenu from '@/components/aside-menu/AsideMenu.vue'
import AsideContent from '@/components/aside-content/AsideContent.vue'
import TransitionComponent from '@/components/common/transition/Transition.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default {
  components: {
    TransitionComponent,
    Navbar,
    Summary,
    AsideMenu,
    AsideContent
  },
  setup() {
    const store = useStore()

    const authUserData = computed(() => store.getters['auth/authUserData'])
    const route = useRoute()

    const isCollapsedAsideMenu = ref(false)
    const isOpenAsideContent = computed(() => route.name !== 'dashboard')

    return {
      authUserData,
      isCollapsedAsideMenu,
      isOpenAsideContent
    }
  }
}
</script>
