<template>
  <div
    class="fixed h-full z-10 pt-16 bg-white w-80 shadow-md transition-all duration-500"
  >
    <div class="p-4 overflow-auto">
      <router-view v-slot="{ Component }">
        <TransitionComponent name="slide-fade">
          <component :is="Component" />
        </TransitionComponent>
      </router-view>
    </div>
  </div>
</template>
<script>
import TransitionComponent from '@/components/common/transition/Transition.vue'
export default {
  components: { TransitionComponent }
}
</script>
