<template>
  <div
    class="fixed h-full z-10 left-0 bg-gray-800 pt-16 text-gray-300  text-sm  flex flex-col justify-between transition-all duration-500 ease-in-out"
  >
    <ul>
      <li
        :key="key"
        v-for="(item, key) in menuItems"
        class="hover:text-white hover:bg-gray-700 cursor-pointer "
      >
        <router-link :to="item.to" class="px-4 py-2 block">
          <div class="flex items-center">
            <div class="mr-2">
              <component :is="item.icon" class="w-5 h-5" />
            </div>
            <div v-show="!showText">
              {{ item.text }}
            </div>
          </div>
        </router-link>
      </li>
    </ul>
    <ul>
      <li
        class="hover:text-white hover:bg-gray-700 cursor-pointer border-t border-gray-500"
        @click="$emit('update:isCollapsed', !isCollapsed)"
      >
        <div class="px-4 py-2 block">
          <div class="flex items-center">
            <div class="mr-2">
              <ChevronDoubleLeftIcon v-if="!isCollapsed" class="w-5 h-5" />
              <ChevronDoubleRightIcon v-else class="w-5 h-5" />
            </div>
            <div v-show="!showText">
              Colapsar
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, watch, toRefs } from 'vue'
import { NewspaperIcon } from '@heroicons/vue/outline'
import { UserIcon } from '@heroicons/vue/outline'
import { GlobeAltIcon } from '@heroicons/vue/outline'
import { AcademicCapIcon } from '@heroicons/vue/outline'
import { BadgeCheckIcon } from '@heroicons/vue/outline'
import { ChevronDoubleLeftIcon } from '@heroicons/vue/solid'
import { ChevronDoubleRightIcon } from '@heroicons/vue/solid'
import { BriefcaseIcon } from '@heroicons/vue/solid'

export default {
  components: {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon
  },
  props: {
    isCollapsed: {
      type: Boolean
    }
  },
  setup(props) {
    const menuItems = [
      {
        text: 'Banner',
        to: { name: 'banner' },
        icon: NewspaperIcon
      },
      {
        text: 'Acerca de mi',
        to: { name: 'about-me' },
        icon: UserIcon
      },
      {
        text: 'Redes sociales',
        to: { name: 'social-networks' },
        icon: GlobeAltIcon
      },
      {
        text: 'Estudios',
        to: { name: 'studies' },
        icon: AcademicCapIcon
      },
      {
        text: 'Cursos',
        to: { name: 'courses' },
        icon: BadgeCheckIcon
      },
      {
        text: 'Experiencias',
        to: { name: 'experiences' },
        icon: BriefcaseIcon
      }
      /*{
        text: 'Proyectos',
        to: { name: 'projects' }
      }*/
    ]
    const showText = ref(false)
    var isCollapsed = toRefs(props).isCollapsed

    watch(isCollapsed, newValue => {
      if (!newValue) {
        setTimeout(() => {
          showText.value = false
        }, 500)
      } else {
        showText.value = true
      }
    })

    return {
      menuItems,
      showText
    }
  }
}
</script>

<style scoped>
.active {
  background: white;
  color: black;
}
</style>
